import React, { ReactComponent } from 'react';
import { Link } from 'gatsby';
import ReactPlayer from 'react-player/lazy';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';

import {
  productOverviewWrapper,
  columnWrapper,
  textWrapper,
  heading,
  reactPlayer,
  playerWrapper
} from './b4l_product_overview.module.scss';

import BendableLabsLogo from '../components/footer/BendableLabsLogo';

import PdfLogo from '../svg/PDF_file_icon.svg';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";


const BendableForLibrariesPage = () => {
  return (
    <Layout pageTitle="Bendable for Libraries Downloads">

      <div id="page" className="Site" >
        <a className="skip-link screen-reader-text" href="#primary">Skip to content</a>

        <header id="masthead" className="Site-header">
          <div className="Site-branding">
            <a href="/" className="custom-logo-link" rel="home"><img width="236" height="74" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/bendable-logo.svg" className="custom-logo" alt="Bendable" /></a>		</div>
          
            <div className="Community-header">
              <div className="Community-name"></div>  			
              <div className="back">
                <Link to="/maine">
                  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M15 7.3132C15 7.56181 14.9012 7.80024 14.7254 7.97603C14.5496 8.15183 14.3112 8.25059 14.0626 8.25059H3.20194L7.22712 12.2739C7.31427 12.361 7.38341 12.4645 7.43058 12.5784C7.47775 12.6923 7.50202 12.8143 7.50202 12.9376C7.50202 13.0608 7.47775 13.1829 7.43058 13.2967C7.38341 13.4106 7.31427 13.5141 7.22712 13.6012C7.13996 13.6884 7.0365 13.7575 6.92262 13.8047C6.80875 13.8519 6.6867 13.8761 6.56344 13.8761C6.44019 13.8761 6.31814 13.8519 6.20426 13.8047C6.09039 13.7575 5.98692 13.6884 5.89977 13.6012L0.275395 7.97687C0.188099 7.8898 0.118838 7.78635 0.0715815 7.67247C0.0243247 7.55858 0 7.4365 0 7.3132C0 7.1899 0.0243247 7.06781 0.0715815 6.95392C0.118838 6.84004 0.188099 6.7366 0.275395 6.64952L5.89977 1.02515C6.07578 0.84913 6.31452 0.750244 6.56344 0.750244C6.81237 0.750244 7.0511 0.84913 7.22712 1.02515C7.40314 1.20117 7.50202 1.4399 7.50202 1.68882C7.50202 1.93775 7.40314 2.17648 7.22712 2.3525L3.20194 6.3758H14.0626C14.3112 6.3758 14.5496 6.47456 14.7254 6.65036C14.9012 6.82615 15 7.06458 15 7.3132Z" fill="#A8A8A8"/>
                  </svg>
                  &nbsp;Back
                </Link>
              </div>
            </div>
          
        </header>

        <main id="primary" className="Site-main">
          <div className={productOverviewWrapper}>
            <article id="post-41" className="post-41 communities type-communities status-publish has-post-thumbnail hentry">
              <div>&nbsp;</div>
              <div className={columnWrapper}>

                <div className={textWrapper}>
                  
                  <div className={heading}>Bendable for Libraries Product Overview</div>
                  <div style={{ paddingBottom: '10px', height: '48px' }}>
                    <PdfLogo style={{ height: '32px', width: '32px', float: 'left' }} />
                    <div style={{ paddingLeft: '8px', paddingTop: '2px', float: 'left' }}>
                      <a href="/Bendable%20for%20Libraries%20%2B%20Financials.pdf" target="_blank" style={{ fontSize: '1.2rem', textDecoration: 'underline' }}>
                        {/* <StaticImage src="../images/PDF_32.png" alt="PDF file icon" />  */}
                        B4L + Financials
                      </a>
                    </div>
                  </div>

                  <div className={heading}>Welcome to South Bend</div>
                  <div className={playerWrapper}>
                    <ReactPlayer
                      className={reactPlayer}
                      url="https://vimeo.com/514098688"
                      width="100%"
                      height="100%"
                      config={{
                        vimeo: {
                          playerOptions: { 
                            autoplay: false,
                            controls: true
                          }
                        }
                      }}
                    />
                  </div>

                  <div className={heading}>How Bendable Works</div>
                  <div className={playerWrapper}>
                    <ReactPlayer
                      className={reactPlayer}
                      url="https://vimeo.com/514098583"
                      width="100%"
                      height="100%"
                      config={{
                        vimeo: {
                          playerOptions: { 
                            autoplay: false,
                            controls: true
                          }
                        }
                      }}
                    />
                  </div>

                </div>

              </div>
            </article>
          </div>
        </main>

        <footer id="colophon" className="Site-footer">
          <div className="Site-info">
            <div className="Site-logos">
              {/* <a href="https://www.maine.gov/msl/" target="_blank" rel="noreferrer">
                <img width="146" height="146" src="https://bendable.s3.us-west-1.amazonaws.com/marketing/Maine-State-Library-Logo.png" className="attachment-full size-full" alt="" loading="lazy" />
              </a> */}

              <div style={{ width: '60px', height: '30px' }} />
              
              <BendableLabsLogo
                width = {{ xs: '150px', sm: '200px', md: '200px', lg: '200px', xl: '250px' }}
              />

            </div>		
            
          </div>
          
        </footer>
      </div>

      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/navigation.js?ver=1.0.0' id='bendable-navigation-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/flickity.js?ver=2.2' id='flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/mobile-flickity-slider.js?ver=2.2' id='custom-flickity-scripts-js'></script>
      <script src='https://bendable.s3.us-west-1.amazonaws.com/marketing/wp-embed.min.js?ver=5.7.2' id='wp-embed-js'></script>
    </Layout>
  )
}

export default BendableForLibrariesPage;