// extracted by mini-css-extract-plugin
export var productOverviewWrapper = "b4l_product_overview-module--productOverviewWrapper--Ck86G";
export var heroImage = "b4l_product_overview-module--heroImage--qTDef";
export var columnWrapper = "b4l_product_overview-module--columnWrapper--0Mo3w";
export var headingWrapper = "b4l_product_overview-module--headingWrapper--Ma5E0";
export var heading = "b4l_product_overview-module--heading--pV7ip";
export var instance = "b4l_product_overview-module--instance--B9+Ge";
export var subheading = "b4l_product_overview-module--subheading--N5+DL";
export var textWrapper = "b4l_product_overview-module--textWrapper--i0U+X";
export var playerWrapper = "b4l_product_overview-module--playerWrapper--krej1";
export var reactPlayer = "b4l_product_overview-module--reactPlayer--OMc-F";
export var latestnewsarticleheadline = "b4l_product_overview-module--latestnewsarticleheadline--gA0IG";
export var dateline = "b4l_product_overview-module--dateline--op4c9";
export var newsArchives = "b4l_product_overview-module--newsArchives--iKT8Q";
export var link = "b4l_product_overview-module--link--CR8nh";
export var headingLogo = "b4l_product_overview-module--headingLogo--SQcIu";
export var photosWrapper = "b4l_product_overview-module--photosWrapper--G2dR3";
export var photo = "b4l_product_overview-module--photo--ifo2K";
export var rightPhoto = "b4l_product_overview-module--rightPhoto--IUWQB";
export var photoCaption = "b4l_product_overview-module--photoCaption--Y-1TV";
export var rightPhotoCaption = "b4l_product_overview-module--rightPhotoCaption--timpN";
export var rightCaptions = "b4l_product_overview-module--rightCaptions--rGXHp";